import React from "react"
// import { Link } from "gatsby"

// import Image from "../image"
// import SignupModalTrigger from '../home/signupModal'

// shows the header for blog posts
const Header = ({title, author, published, updated, tags}) => {

  return (
    <div className="text-black">
      <h1 className="text-center">{title}</h1>
      {author ? (
        <div className="text-xl md:text-2xl text-center font-semibold mt-4">By {author}</div>
      ) : ''}
      {published ? (
        <div className="text-sm md:text-base text-center font-semibold mt-2">Published {published}
          {updated ? (
            <span>, updated {updated}</span>
          ) : ''}
        </div>
      ) : ''}
    </div>
  );
}

export default Header;
