import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import BlogPostCard from "../components/blog/blogpostcard";
import Header from '../components/blog/header';
import Subscribe from '../components/common/subscribe';


const NewsletterPage = ({data, path}) => (
  <Layout pageTitle="Hive Index Newsletter" enableShare={true} >
    <SEO title="The Bee's Knees Newsletter" description="Subscribe to the Hive Index newsletter for new communities and thoughts from those working in the community space."
      generateImage={true} path={'/newsletter/'}
    />

    <div className="mx-auto">
      <div id="recent" className="">
        <Subscribe />
      </div>
    </div>

  </Layout>
)


export default NewsletterPage;
