import React, { useState } from 'react';

import Image from "../common/image"

import { trackEvent } from "../../utils/tracking"


const SubscriptionForm = ({topics, community, platform, hideTitle}) => {
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const FORM_ID = 2737170; // Powell
  const FORM_URL = `https://app.convertkit.com/forms/${FORM_ID}/subscriptions`;

  const handleSubmit = async (e) => {
      e.preventDefault();

      if (submitting) return;

      setSubmitting(true)
      const data = new FormData(e.target);
      try {
          const response = await fetch(
              FORM_URL,
              {
                  method: 'post',
                  body: data,
                  headers: {
                      accept: 'application/json',
                  },
              }
          );
          setEmail('');
          const json = await response.json();
          setSubmitting(false)
          if (json.status === 'success') {
              setStatus('SUCCESS');

              // track event
              trackEvent('Subscribed', {
                'location': 'redirect',
                'form': FORM_ID,
                'community': community,
                'platform': platform,
                'topics': topics,
              })
          }
      } catch (err) {
          setStatus('ERROR');
          console.log(err);
          setSubmitting(false)
      }
  };

  const handleInputChange = event => {
      const {value} = event.target;
      setEmail(value);
  }

  return (
    <div className="mx-auto">
      <div className="bg-yellow-300 text-gray-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="py-12 px-4 sm:px-12">
          <div className="lg:self-center text-left">

            {status === 'ERROR' ? (
              <p className="bg-red-500 text-white font-semibold p-4 mb-4 rounded-md">Oops, Something went wrong! try again.</p>
            ) : ''}

            {true || !hideTitle ? (
              <h2 className="text-xl font-extrabold sm:text-xl">
                <span className="block">The Bee's Knees Newsletter</span>
              </h2>
            ) : ''}
            
            <p className="mt-4 text-lg leading-6 font-semibold">
              Subscribe to guest posts from community builders, entrepreneurs, and researchers, as well as new community launches and additional topics curated.
            </p>

            {status === 'SUCCESS' ? (
              <p className="bg-green-500 text-white font-semibold p-2 mt-2 rounded-md">
                Success! Please click the link in your confirmation email.
              </p>
            ) : (
              <form
                className="mt-8 block flex"
                action={FORM_URL}
                method="post"
                onSubmit={handleSubmit}
              >
                <input
                  type="email"
                  aria-label="Your email"
                  name="email_address"
                  placeholder="Your email address"
                  onChange={handleInputChange}
                  value={email}
                  className="flex-grow bg-white border border-transparent focus:outline-none focus:ring-rose-500 focus:border-rose-500 rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-900"
                  required
                />
                <input
                  type="hidden"
                  name="fields[topics]"
                  value={topics}
                  className="hidden"
                />
                <input
                  type="hidden"
                  name="fields[platform]"
                  value={platform}
                  className="hidden"
                />
                <input
                  type="hidden"
                  name="fields[communityredirect]"
                  value={community}
                  className="hidden"
                />
                <button type="submit" disabled={submitting} className="ml-2 bg-rose-500 text-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium">
                  Subscribe
                </button>
              </form>
            )}

            
          </div>
        </div>
        <div className="aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <Image
            className="object-contain pt-2"
            fileName="bees-knees.png" alt="Hive Index Newsletter"
          /> 
          {/*<Image
            className="transform translate-x-6 translate-y-6 rounded-md object-cover  sm:translate-x-16 lg:translate-y-20"
            fileName="fb7yNPbT0l8.jpg" alt="Hive Index Newsletter"
          />  */}
          {/*<img
            className="transform translate-x-6 translate-y-6 rounded-md object-cover  sm:translate-x-16 lg:translate-y-20"
            src="https://source.unsplash.com/fb7yNPbT0l8/"
            alt="Mailbox"
          />*/}
        </div>
      </div>
    </div>
  )
};

export default SubscriptionForm